import * as React from "react"
import { PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import DefaultSidebar from "components/Sections/Sidebar/DefaultSidebar"
import ImageViewer from "components/UIElements/ImageViewer"
// Models
import { IOptionalMetaProps } from "models/Seo"

const NotFoundPage: React.FC<PageProps> = () => {
  const seo: IOptionalMetaProps = {
    title: "Página no encontrada :(",
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Imagen</h2>
        <ImageViewer
          translated_image={"https://tsuini.gatsbyjs.io/static/ff8f39ef5a473d7af4eaae0cc051bf71/heikousen.webp"}
          image={"https://tsuini.gatsbyjs.io/static/a5e76a9b3b72cfe5282de55f0d705a5c/yamiyo.webp"}
        />
      </Main>
      <DefaultSidebar />
    </Layout>
  )
}

export default NotFoundPage
